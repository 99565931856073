function customSelect(a) {
    el = $(a);
    text = el.find("option:selected").text();
    if (text) el.closest(".custom-select").removeClass("placeholder"); else {
        el.closest(".custom-select").addClass("placeholder");
        text = el.siblings(".replacement").attr("data-text");
    }
    el.siblings(".replacement").html(text);
}

function customSelected() {
    if ($(".custom-select").length) $(".custom-select").each(function() {
        el = $(this).find("select");
        text = el.find("option:selected").text();
        if (text) el.closest(".custom-select").removeClass("placeholder"); else {
            el.closest(".custom-select").addClass("placeholder");
            text = el.siblings(".replacement").attr("data-text");
        }
        el.siblings(".replacement").html(text);
    });
}

$(function() {	
	$('.box-left').height( $(window).height());
		
	$(".icon-search").click(function() {
		$(".form-control.input-search").addClass("open");
		setTimeout(function() {
			$("#input-auto").focus()
		}, 500)
    });
	
	$(".icon-search2").click(function() {
		$(".form-control.input-search2").addClass("open");
		setTimeout(function() {
			$("#input-auto2").focus()
		}, 500)
    });
	
	$("html").click(function(a) {
        if (!$(a.target).is(".icon-search") && !$(a.target).parents().is(".icon-search img") && !$(a.target).is(".input-search") && !$(a.target).parents().is(".pos-search")) {
           $(".form-control.input-search").removeClass("open");
        }
    });
	$("html").click(function(a) {
        if (!$(a.target).is(".icon-search2") && !$(a.target).parents().is(".icon-search2 img") && !$(a.target).is(".input-search2") && !$(a.target).parents().is(".pos-search")) {
           $(".form-control.input-search2").removeClass("open");
        }
    });
	
	$("html").click(function(a) {
        if (!$(a.target).is(".toggle-menu") && !$(a.target).parents().is(".toggle-menu") && !$(a.target).is("#offcanvas-menu") && !$(a.target).parents().is("#offcanvas-menu")) {
            $("body").removeClass("offcanvas-menu-open");
            $(".bg-dark").hide();
            $(".bg-dark").animate({
                opacity: 0
            });
        }
    });
	
    $(document).keyup(function(a) {
        if (27 == a.keyCode) {
            $("body").removeClass("offcanvas-menu-open");
            $(".bg-dark").hide();
            $(".bg-dark").animate({
                opacity: 0
            });
        }
    });
    $(".close-menu").click(function(a) {
        a.preventDefault();
        $("body").removeClass("offcanvas-menu-open");
        $(".bg-dark").hide();
        $(".bg-dark").animate({
            opacity: 0
        });
    });
    $(".toggle-menu").click(function(a) {
        a.preventDefault();
        $("body").toggleClass("offcanvas-menu-open");
        $(".bg-dark").show();
        $(".bg-dark").animate({
            opacity: .85
        });
    });
	
	$(".onlynumber").keypress(function (e) {
		if (e.which != 8 && e.which != 0 && (e.which < 48 || e.which > 57)) {
			return false;
		}
	});
	
	$('.body-fixed').height( $(window).height());
	
	$('.dropdown-listmenu').click(function() {
		$(this).next().slideToggle('fast');
		$(this).toggleClass('active');
	});
	
	$('.dropdown-listmenu1').click(function() {
		$('.list-menu1').slideToggle('fast');
		$('.dropdown-listmenu1').toggleClass('active');
	});
	
	$('.dropdown-listmenu2').click(function() {
		$('.list-menu2').slideToggle('fast');
		$('.dropdown-listmenu2').toggleClass('active');
	});
	
	customSelected();
	
	$(window).scroll(function() {
		var a = $(window).scrollTop();		
		if (a >= 800) {
			$("#toTop").fadeIn();
		} else {
			$("#toTop").fadeOut();
		} if($(window).scrollTop() + $(window).height() < $(document).height() - $("#footer").height()) {
			$('#toTop').css("position","fixed");
			$('#toTop').css("bottom","30px");
			$('#toTop').css("right","30px");
		} if($(window).scrollTop() + $(window).height() > $(document).height() - $("#footer").height()) {
			$('#toTop').css("position","relative");
			$('#toTop').css("bottom","50px");
			$('#toTop').css("right","10px");
		}
    });

    $("#toTop").click(function() {
		$("html, body").animate({scrollTop: 0}, 1000);
    });
});